import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/home"

const IndexPage = () => (
  <Layout>
    <SEO title="Oliver Altergott" lang="de" description="My Portfolio" />
    <Home />
  </Layout>
)

export default IndexPage
