import * as React from "react"

import { navigate } from "gatsby"


import "../components/layout.css"

const axios = require('axios');

var config = {
  "headers": {
    "Accept": "text/plain"
  },
  responseType: 'text'
};


//===============COMPONENT_HOME===============


const get = () => axios.get(`https://icanhazdadjoke.com/`, config);

export default function Home() {
  return (
    <div className="Home">
      <div id="dadjoke" style={{ scrollMarginTop: "65px" }}>
        <DadJoke />
      </div>
    </div>
  )
}

class DadJoke extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: ""
    }

  }

  async componentDidMount() {
    const res = await get();
    this.setState({ data: res.data });
  }

  render() {
    return (
      <section className="DadJoke">
        <h2 style={{ color: "white" }}>
          Random dad joke
        </h2>
        <q style={{ color: "white" }}>
          {this.state.data}
        </q>
      </section>
    )
  }
}
